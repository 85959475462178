const color = {
    poor: '#FC828D',
    fair: '#FFD146',
    excellent: '#44EA86'
}

const textColor = {
    poor: 'text-[#FC828D]',
    fair: 'text-[#FFD146]',
    excellent: 'text-[#44EA86]'
}

const bgColor = {
    poor: 'bg-[#FC828D]',
    fair: 'bg-[#FFD146]',
    excellent: 'bg-[#44EA86]'
}

const chipColor = {
    poor: 'bg-[#FF35481A] text-[#FF3548]',
    fair: 'bg-[#FFBB341A] text-[#FFBB34]',
    excellent: 'bg-[#01C8511A] text-[#01C851]'
}

export { color, textColor, bgColor, chipColor }